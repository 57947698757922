<template>
    <modal ref="modalEditarLiquidador" titulo="Editar Liquidador" tamano="modal-xl" no-aceptar adicional="Guardar" @adicional="putLiquidador">
        <cargando v-if="cargando" />
        <ValidationObserver ref="validacion">
            <div class="row mx-0 px-4 pl-5">
                <div class="col-auto">
                    <slim-cropper 
                    ref="fotoUser" 
                    :options="slimOptions" 
                    class="border cr-pointer" 
                    style="height:162px;width:162px;background-color:#F5F5F5;border-radius:6px;" 
                    />
                    <p class="text-center text-gris2 f-15 mt-2">
                        Foto
                    </p>
                </div>
                <div class="col">
                    <div class="row mx-0">
                        <div class="col">
                            <p class="text-general f-12 pl-3">
                                Nombre
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:25" name="Nombre">
                                <el-input v-model="nombre" class="w-100" size="small" maxlength="25" placeholder="Nombre" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <p class="text-general f-12 pl-3">
                                Cargo
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="max:25" name="Cargo">
                                <el-input v-model="cargo" class="w-100" size="small" maxlength="25" placeholder="Cargo" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 my-4">
                        <div class="col">
                            <p class="text-general f-12 pl-3">
                                Correo electrónico de acceso
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="email|max:70" name="Correo electrónico">
                                <el-input v-model="correoElectronico" size="small" maxlength="70" placeholder="Correo electrónico" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col" />
                    </div>
                    <div class="row mx-0 my-3">
                        <div class="col">
                            <p class="text-general f-12 pl-3">
                                Contraseña de acceso
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="max:120" name="Contraseña de acceso">
                                <el-input v-model="contrasena" size="small" maxlength="120" class="w-100" placeholder="Contraseña" @blur="verificarContrasenas" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <p class="text-general f-12 pl-3">
                                Repetir contraseña
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="max:120" name="Repetir contraseña">
                                <el-input v-model="repContrasena" size="small" maxlength="120" class="w-100" placeholder="Repetir contraseña" @blur="verificarContrasenas" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span><br />
                                <span v-if="contrasenas_mal" class="text-danger w-100 f-11">Contraseñas no coinciden</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 pl-3 mb-3">
                        <div class="col-auto px-2" />
                        <el-checkbox v-model="tipoLiquidador" class="check-dark" />
                        <div class="col px-2 text-general f-15">
                            Tipo liquidador
                        </div>
                    </div>
                    <div class="row mx-0 pl-3 mb-3">
                        <div class="col-auto px-2" />
                        <el-checkbox v-model="tipoContacto" class="check-dark" @change="seleccionarTipoContacto" />
                        <div class="col px-2 text-general f-15">
                            Tipo contacto
                        </div>
                    </div>
                    <div class="row mx-0 my-3">
                        <div class="col">
                            <p class="text-general f-12 pl-3">
                                Télefono de contacto
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="integer" name="Teléfono de contacto">
                                <el-input v-model="telefono" size="small" class="w-100 soloNumeros" placeholder="Teléfono" :disabled="checkContacto" @focus="soloNumeros" @change="validarTelefono" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <p class="text-general f-12 pl-3">
                                Correo electrónico de contacto
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="email|max:70" name="Correo electrónico de contacto">
                                <el-input v-model="correoContacto" size="small" max="70" class="w-100" placeholder="Correo electrónico" :disabled="checkContacto" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Service from '~/services/nomina/liquidadores'
export default {
    data(){
        return {
            cargando: false,
            checkContacto: true,
            contrasenas_mal: false,
            tipoLiquidador: true,
            tipoContacto: false,
            idLiquidador: '',
            nombre: '',
            cargo: '',
            correoElectronico: '',
            contrasena: '',
            repContrasena: '',
            telefono: '',
            correoContacto: '',
            slimOptions: {
                ratio: '1:1',
                label:'Subir foto',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
        }
    },
    computed: {
        id_convenio(){
            return this.$route.params.id_convenio
        },
    },
    methods: {
        async putLiquidador(){
            try {
                const valid = await this.$refs.validacion.validate();
                if(!valid)return;
                if(this.contrasenas_mal === true)return
                this.cargando = true;
                const archivo = this.$refs.fotoUser.instanciaCrop.dataBase64.output.image;
                const form = {
                    nombre: this.nombre,
                    cargo: this.cargo,
                    correo: this.correoElectronico,
                    password: this.contrasena,
                    telefono: this.telefono,
                    correo_contacto: this.correoContacto,
                    tipo_liquidador: this.tipoLiquidador === true ? 1 : 0,
                    tipo_contacto: this.tipoContacto === true ? 1 : 0,
                    foto: archivo
                };
                const {data} = await Service.puLiquidador(this.idLiquidador, form);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$emit('actualizar');
                this.limpiar();
                this.$refs.modalEditarLiquidador.toggle();
            } catch(e){
                this.error_catch(e)
            } finally {
                this.cargando = false;
            }
        },
        seleccionarTipoContacto(){
            this.checkContacto = !this.checkContacto;
            this.telefono = "";
            this.correoContacto = "";
        },
        verificarContrasenas(){
            if(this.contrasena === '' && this.repContrasena === '')return this.contrasenas_mal = false;
            if(this.repContrasena === '')return;
            if(this.contrasena.toLowerCase() != this.repContrasena.toLowerCase()){
                return this.contrasenas_mal = true;
            }
            return this.contrasenas_mal = false;
        },
        soloNumeros(){
            $('.soloNumeros').keypress(function(event){
                // Bloqueo de letras y caracteres especiales
                let char = String.fromCharCode(event.keyCode);
                let pattern = '[a-zA-Z|°!"#$%&/()=?¿¡]';
                if (char.match(pattern)){
                    return false;
                }
            });
        },
        validarTelefono(){
            // Bloqueo de letras y caracteres especiales si es por pegado de texto
            let pattern = '[a-zA-Z|°!"#$%&/()=?¿¡]';
            if (this.telefono.match(pattern)){
                this.telefono = "";
            }
        },
        limpiar(){
            this.cargando = false;
            this.checkContacto = true;
            this.contrasenas_mal = false;
            this.tipoLiquidador = true;
            this.tipoContacto = false;
            this.nombre =  '';
            this.cargo = '';
            this.correoElectronico = '';
            this.contrasena = '';
            this.repContrasena = '';
            this.telefono = '';
            this.correoContacto = '';
            this.$refs.validacion.reset();
            this.$refs.fotoUser.instanciaCrop.remove();
        },
        toggle(data){
            this.cargando = true;
            this.contrasenas_mal = false;
            this.idLiquidador = data.id;
            this.nombre = data.usuario;
            this.cargo = data.cargo;
            this.correoElectronico = data.correo;
            this.tipoLiquidador = data.liquidador === 1 ? true : false;
            this.tipoContacto = data.contacto === 1 ? true : false;
            if(data.contacto === 1){
                this.checkContacto = false;
                this.telefono = data.contacto_telefono;
                this.correoContacto = data.contacto_correo;
            }
            if(data.foto != null){
                this.$refs.fotoUser.set_image(data.usuario_foto)
            } else {
                this.$refs.validacion.reset();
            }
            this.cargando = false;
            this.$refs.modalEditarLiquidador.toggle();
        }
    }
}
</script>